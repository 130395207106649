/* eslint-disable */

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import Axios from "axios";
import {appConfig} from "./config.js";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import BootstrapVue from "bootstrap-vue";

Vue.use(appConfig);
window.appConfig = appConfig;

Vue.use(BootstrapVue);

Vue.config.productionTip = false

Axios.defaults.baseURL = appConfig.baseAPIURL;

Vue.prototype.$http = Axios;

window.NProgress = require('nprogress');

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
