/*eslint-disable */
import Vue from 'vue'
import VueRouter from 'vue-router'

import App from "../App";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'getStartedForm',
    component: App,
  },
  {
    path: '/sign/:access_code',
    name: 'getStartedForm',
    component: App,
  },
  {
    path: '/forms/back-order/sign/:access_code',
    name: 'getStartedForm',
    component: App,
  },
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
