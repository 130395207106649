<template>
  <div class="wrapper">
    <div v-if="!isLoading && (_isEmpty(backOrder) || (backOrder && backOrder.status !== 'sent') || isSavedData)" class="">
      <div  style="margin-top: 20%; padding: 20px">
        <template v-if="_isEmpty(backOrder)">
          <div class="intro-text d-flex" style="justify-content: center; flex-direction: column; align-items: center;">
            <div class="big-icon">
              <i class="bx bxs-sad" style="color:#FF5E5E"></i>
            </div>
            <h1 style="text-align: center; font-weight: bold; margin-bottom: 15px;">We Found a Problem</h1>
            <p style="text-align: justify">Failed to load the data for the form. Please try again</p>
          </div>
        </template>
        <template v-else-if="isSavedData || backOrder.status !== 'sent'">
          <div class="intro-text d-flex" style="justify-content: center; flex-direction: column; align-items: center;">
            <div class="big-icon">
              <i class="bx bxs-check-circle" style="color:#2DCA73"></i>
            </div>
            <h1 style="text-align: center; font-weight: bold; margin-bottom: 15px;">Success</h1>
            <p v-if="isSavedData" style="text-align: justify">You have successfully {{savedStatus}} the form</p>
            <p v-else style="text-align: justify">This form has already been successfully signed</p>
          </div>
        </template>
      </div>
    </div>
    <div v-else-if="!isLoading">
      <div class="header">Back Order - Part Acceptance Agreement</div>
      <div  class="wrapper-form">
        <form>
          <div class="form-group row pr-3" style="padding-top: 15px">
            <label class="col col-form-label"><span style="font-weight: 700">{{backOrder.data.description.supplier}}</span> <span class="font-weight-normal">requires you to read and accept the following Terms and Conditions for the supply of the following part/s with your order.</span></label>
          </div>
          <div class="d-flex justify-content-beetwen pr-3 pt-3">
            <div class="d-flex col-6 pl-0">
              <p class="font-weight-bold pr-2">Order Number: </p>
              <p class="">{{backOrder.data.description.orderNumber}}</p>
            </div>
            <div class="d-flex col-6 pl-0">
              <p class="font-weight-bold pr-2">Invoice Number: </p>
              <p class="">{{backOrder.data.description.invoiceNumber}}</p>
            </div>
          </div>
          <div class="d-flex pr-3 ">
            <div class="d-flex col-6 pl-0">
              <p class="font-weight-bold pr-2">Part/s: </p>
              <p class=""></p>
            </div>
            <div class="d-flex col-6 pl-0">
              <p class="font-weight-bold pr-2">Make: </p>
              <p class="">{{backOrder.data.description.make}}</p>
            </div>
          </div>
          <div class="form-group row pr-3 pl-3">
            <!--            <input-->
            <!--                type="text"-->
            <!--                class="col-12 form-control"-->
            <!--            />-->
            <div style="min-height: 32px; height: auto; padding-top: 7px; margin-top: 10px; line-height: 18px" class="col-12 form-control" v-for="(prt, id) in backOrder.data.parts" :key="prt.orderPartId">
              <b>Part: </b> {{prt.partDescription}} <span v-if="prt.partNumber">| {{prt.partNumber}}</span> | QTY {{prt.qty}} | {{prt.buyPrice | formatMoney}}
            </div>
          </div>
          <div class="form-group row pr-3 pl-3" v-if="backOrder.data.settings.displayOrderAcceptanceTICs">
            <label class="col col-form-label pl-0">Terms & Conditions</label>
            <div class="disabled-vue-editor-supp-order">
              <vue-editor
                  :disabled="true"
                  v-model="backOrder.data.settings.termsAndConditions"
                  :editorToolbar="editorToolbar"></vue-editor>
            </div>
          </div>
          <div class="w-100 text-right row p-3" v-if="backOrder.data.settings.requireAcceptanceOfOrderTICs">
            <label class="form-check-inline checkbox">
              <input class="form-check-input" type="checkbox" v-model="backOrderResult.acceptanceOfTics" :disabled="backOrder.status !== 'sent'">
              <span style="border: 2px solid rgb(121, 177, 187) !important; background-color: rgba(27, 30, 56, 0.03) !important;" class="icon clickable"><i class='bx bx-check'></i></span><span
                class="text">I have read & accept the suppliers Terms and Conditions</span>
            </label>
          </div>
          <div v-if="backOrder.data.settings.requireESignature" class="form-group row p-3 position-relative" @mouseover="checkSignature">
            <label class="col col-form-label pl-0">E-Signature</label>
            <template>
              <div class="col-12 p-0 special-order-signature-block" ref="specialOrderBlock">
                <template v-if="backOrder.status !== 'sent'">
                  <div class="special-order-signature-canvas"> <img :src="backOrder.eSignature" alt=""></div>
                </template>
                <VPerfectSignature v-else :stroke-options="strokeOptions" ref="signaturePad"
                                   class="special-order-signature-canvas"/>
              </div>
              <a
                  v-if="!isEmptySignature && backOrder.status === 'sent'"
                  class="control-elem-1__item"
                  @click.prevent="clearSignature"
                  v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Clear this signature', placement:'leftbottom'}">
                <i class="bx bx-trash"></i>
              </a>
            </template>
          </div>
          <template v-if="backOrder.data.settings.requireSigneeToAddNameOrJob">
            <div class="form-group row pr-3 pl-3">
              <label class="col-4 col-form-label pl-0">Accepted By: </label>
              <input
                  type="text"
                  v-model="backOrderResult.acceptedBy"
                  :disabled="backOrder.status !== 'sent'"
                  class="col-8 form-control"
              />
            </div>
            <div class="form-group row pr-3 pl-3">
              <label class="col-4 col-form-label pl-0">Position Held: </label>
              <input
                  type="text"
                  v-model="backOrderResult.positionHeld"
                  :disabled="backOrder.status !== 'sent'"
                  class="col-8 form-control"
              />
            </div>
          </template>
        </form>
        <div class="d-flex" style="justify-content: end">
          <div v-if="backOrder.status === 'sent'" class="">
            <b-button  @click="acceptNonReturnable('declined')" class="input-btn decline" style="margin-right: 15px">Decline</b-button>
            <b-button  @click="acceptNonReturnable('accepted')" :disabled="!isCanAccept" class="input-btn">Accept</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import { VPerfectSignature } from 'v-perfect-signature'
    import {VueEditor} from 'vue2-editor'
    import Axios from "axios";
    import Vue from "vue";
    import _ from "lodash";
    import {UAParser} from "ua-parser-js";
    import {isMobile, isTablet, isMobileOnly} from "mobile-device-detect";
    import accounting from 'accounting'
    /* eslint-disable */

    export default {
    name: "app",
    components: {
      VPerfectSignature,
      VueEditor,
    },
    data: function () {
      return {
        access_code: '',
        isEmptySignature: false,
        isLoading: false,
        isSavedData: false,
        savedStatus: '',
        backOrderResult: {},
        backOrder: {
          "id": "",
          "createdBy": "",
          "data": {
            "parts": [],
            "settings": {
              "requireESignature": false,
              "termsAndConditions": "",
              "sendOrderAcceptanceBy": null,
              "displayOrderAcceptanceTICs": false,
              "requireSigneeToAddNameOrJob": false,
              "requireAcceptanceOfOrderTICs": false
            },
            "description": {
              "supplier": "",
              "orderNumber": "",
              "invoiceNumber": null
            }
          },
          "createdOn": "",
          "status": "",
          "sentTo": {
            "id": "",
            "name": ""
          },
          "sentOn": "",
          "sentBy": {
            "id": "",
            "name": ""
          },
          "eSignature": null,
          "signedBy": null,
          "signedByPositionHeld": null
        },
        strokeOptions: {
          size: 4,
          thinning: 0.75,
          smoothing: 0.5,
          streamline: 0.5,
        },

        editorToolbar: [[{
          header: [false, 1, 2, 3, 4, 5, 6]
        }], ["bold", "italic", "underline", "strike"], // toggled buttons
          [{
            align: ""
          }, {
            align: "center"
          }, {
            align: "right"
          }, {
            align: "justify"
          }], [{
            list: "ordered"
          }, {
            list: "bullet"
          },], [{
            indent: "-1"
          }, {
            indent: "+1"
          }], // outdent/indent
          [{
            color: []
          }],
          ["clean"] // remove formatting button
        ],
      };
    },
    created: function () {
      this.access_code = this.$route.params.access_code
      this.loadInfo()
    },
    watch: {},
    mounted() {
      this.$nextTick(() => {
        setTimeout(() => {
          let signatureCanvas = document.getElementsByClassName('special-order-signature-canvas')
          if (signatureCanvas && signatureCanvas[0]) {
            let width = this.$refs.specialOrderBlock.offsetWidth;
            signatureCanvas[0].width = width
            signatureCanvas[0].style.width = width + 'px'
            signatureCanvas[0].height = 100
            signatureCanvas[0].style.height = '100px'
          }
          if (this.$refs.signaturePad) {
            this.$refs.signaturePad.inputPointsHandler()
          }
        }, 100)
      })


      window.addEventListener('resize', this.handleResize);
    },
      beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
      },
      methods: {
      _isEmpty(v) {
        return _.isEmpty(v)
      },
        loadInfo() {
          this.isLoading = true;
          NProgress.start()
          Axios.get(`/public/back-order/${this.access_code}`)
              .then(response => {
                if (response.data._status && response.data.backOrder) {
                  this.backOrder = response.data.backOrder
                  if (this.backOrder.status !== 'sent') {
                    this.backOrderResult.acceptanceOfTics = true
                    this.backOrderResult.acceptedBy = this.backOrder.signedBy
                    this.backOrderResult.positionHeld = this.backOrder.signedByPositionHeld
                  }
                } else {
                  this.backOrder = null;
                }
              })
              .catch(() => {
                this.backOrder = null;
              })
              .finally(() => {
                NProgress.done()
                this.isLoading = false;
              })
        },
        acceptNonReturnable(status) {
          const parser = new UAParser();
          let result = parser.getResult()
          let type =  'Desktop'
          if (this.isMobileOnly) {
            type = 'Phone'
          } else if (this.isTablet) {
            type = 'Tablet'
          }

          let signaturePad = this.$refs['signaturePad']
          let signature = null
          if (signaturePad) {
            signature = signaturePad.toDataURL()
          }
          let data = {
            "eSignature": signature,
            status: status,
            acceptanceOfTics: this.backOrderResult.acceptanceOfTics,
            acceptedBy: this.backOrderResult.acceptedBy,
            positionHeld: this.backOrderResult.positionHeld,
            specialOrderId: this.backOrder.id,

            type: type,
            system: result?.os?.name + ' ' + result?.os?.version,
            browser: result?.browser?.name + ' version ' + result?.browser?.version,
          }
          NProgress.start()
          Axios.post(`/public/back-order/${this.access_code}/sign`,
              data
          )
              .then(response => {
                if (response.data._status) {
                  this.backOrder.status = status
                  this.backOrder.signedBy = this.backOrderResult.acceptedBy
                  this.backOrder.signedByPositionHeld = this.backOrderResult.positionHeld
                  this.backOrder.eSignature = signature

                  this.isSavedData = true;
                  this.savedStatus = status
                }
              })
              .catch(error => {
                console.log(error)
              })
              .finally(() => {
                NProgress.done()
              })
        },
        handleResize() {
          this.$nextTick(() => {
            setTimeout(() => {
              let signatureCanvas = document.getElementsByClassName('special-order-signature-canvas')
              if (signatureCanvas && signatureCanvas[0]) {
                let width = this.$refs.specialOrderBlock.offsetWidth;
                signatureCanvas[0].width = width
                signatureCanvas[0].style.width = width + 'px'
                signatureCanvas[0].height = 100
                signatureCanvas[0].style.height = '100px'
              }
              if (this.$refs.signaturePad) {
                this.$refs.signaturePad.inputPointsHandler()
              }
            }, 100)
          })
        },
        checkSignature () {
          if (this?.$refs?.signaturePad) {
            this.isEmptySignature = this.$refs.signaturePad.isEmpty()
          }
        },
        clearSignature () {
          let signaturePad = this.$refs.signaturePad
          if (signaturePad) {
            signaturePad.clear()
          }
        },
      },
      computed: {
        isCanAccept() {
          let b = true;
          if (this.backOrder.data.settings.requireSigneeToAddNameOrJob) {
            b = b && !!this.backOrderResult.acceptedBy && !!this.backOrderResult.positionHeld
          }
          if (this.backOrder.data.settings.requireESignature) {
            b = b && !this.isEmptySignature
          }
          if (this.backOrder.data.settings.requireAcceptanceOfOrderTICs) {
            b = b && this.backOrderResult.acceptanceOfTics
          }
          return b
        },
        isMobileOnly () {
          return isMobileOnly
        },
        isTablet () {
          return isTablet
        },
        isMobile () {
          return isMobile
        },
      }
  };
    Vue.filter('formatMoney', function (value) {
      return accounting.formatMoney(value)
    })
</script>

<style>
.intro-text {
  line-height: 26px;
}
.big-icon {
  margin: 0 auto;
  margin-bottom: 5px;
}
.big-icon i {
  width: 75px;
  height: 75px;
  font-size: 75px;
  margin: 0;
}

* {
  font-family: 'Inter', sans-serif;
  color: #1C1F39;
}
.wrapper {
  display: flex;
  justify-content: center;
}
.form-control:disabled {
  background-color: white !important;
}
.wrapper-form {
  max-width: 720px;
  padding: 20px;
  padding-top: 0px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 10px;
  border-radius: 3px;
}
.special-order-signature-canvas:hover + .control-elem-1__item,
.special-order-signature-block:hover + .control-elem-1__item {
  opacity: 1;
}
.special-order-signature-canvas {
  height: 100px !important;
  width: 100% !important;
  border: 1px solid rgba(28, 31, 57, 0.25) !important;
  border-radius: 3px;
}
.control-elem-1__item {
  display: block;
  box-sizing: border-box;
  padding: 10px 10px;
  position: absolute;
  top: 56px;
  right: 18px;
  z-index: 9;
  height: 40px;
  border-radius: 3px;
  background-color: #FFFFFF;
  transition: opacity 150ms ease-in;
  border: 1px solid rgba(27, 30, 56, 0.25);
  cursor: pointer;
  opacity: 0;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.col-form-label {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
}
.control-elem-1__item i {
  font-size: 18px;
  color: #FF5E5E;
}

.control-elem-1__item:hover {
  opacity: 1;
}

.ps-tooltip.tooltip.b-tooltip .tooltip-inner {
  border-radius: 10px 0 10px 10px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 8px 0 rgba(27, 30, 56, 0.25);
  color: #1C1F39;
  font-family: "Inter";
  font-size: 13px;
  letter-spacing: 0;
  font-family: 'Inter', sans-serif;
  color: #1C1F39;
  line-height: 18px;
  text-align: center;
  padding: 10px;
}
.ps-tooltip.tooltip.b-tooltip {
  margin-top: 5px;
}
.ps-tooltip.tooltip.b-tooltip.ps-tooltip-round .tooltip-inner {
  border-radius: 10px;
}
.ps-tooltip.tooltip.b-tooltip .arrow {
  display: none;
}

.disabled-vue-editor-supp-order .ql-toolbar {
  display: none !important;
}
.disabled-vue-editor-supp-order,
.disabled-vue-editor-supp-order .quillWrapper{
  width:100%;
}
.disabled-vue-editor-supp-order .ql-container {
  /*width: 650px !important;*/
  border-top: 1px solid #ccc !important;
}

.header {
  background-color: #79B1BB;
  color: white;
  padding: 10px 20px;
  font-size: 20px;
  font-weight: bold;
}
.input-btn {
  font-size: 1rem !important;
  height: auto !important;
  /*color: #1C1F39 !important;*/
  padding: 0.75rem 1rem !important;
  font-weight: 700 !important;
  width: auto !important;
  background-color: #29BBC1 !important;
  border-color: #29BBC1 !important;
}

.input-btn.decline {
  background-color: white !important;
  color: #1C1F39;
  border-color: #29BBC1 !important;
  font-weight: 700;
}
.input-btn.decline:hover {
  color: #1C1F39 !important
}
label.checkbox {
  position: relative;
  height: 25px;
  padding-left: 0px;
  margin-left: 0px;
}
label.checkbox input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: absolute;
  z-index: -1;
  opacity: 0;
}
label.checkbox>span.icon {
  display: flex;
  flex: 0 0 auto;
  box-sizing: border-box;
  height: 25px;
  width: 25px;
  border: 1px solid rgba(27, 30, 56, 0.25);
  border-radius: 3px;
  background-color: rgba(27, 30, 56, 0.03);
  float: left;
  margin-right: 10px;
  cursor: pointer;
}
label.checkbox>input[type="checkbox"]+span.icon i {
  display: none;
}
label.checkbox>input[type="checkbox"]:checked+span.icon i {
  display: inline-block;
  color: white;
}
label.checkbox span.icon i.bx {
  font-size: 24px;
  transform: translate(-2px, -2px) !important;
  color: #79B1BB !important;
}

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #29d;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
  opacity: 1.0;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #29d;
  border-left-color: #29d;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0%   { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes nprogress-spinner {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

#nprogress .bar {
  z-index: 111110 !important;
}

#nprogress .spinner {
  z-index: 111110 !important;
}
#nprogress .spinner {
  width: 150px;
  right: auto;
  left: 50%;
  top: 20px;
  /*margin-left: -73px;*/
  /*padding: 8px 15px;*/
  background: #FFFFFF;
  color: #333333;
  border: 1px solid rgba(0, 0, 0, 0.125);

  margin-left: 5px;
  padding: 10px;
  border-radius: 5px;

}

#nprogress .spinner:after {
  content: "Processing...";
  display: inline-block;
  position: absolute;
  top: 10px;
  left: 48px;
}

#nprogress .bar {
  height: 5px;
  transition-delay: 200ms;
  background: #29BBC1;
}



</style>
